// import './assets/scss/app.scss';
import './assets/main.css';
import './assets/baseapp';
import 'simplebar/dist/simplebar.min.css';
// import './utils/sw.js';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

const app = createApp(App);

Object.values(import.meta.glob('./modules/*.js', { eager: true })).map((i) =>
  i.install?.({ app, router })
);

app.use(router);

app.mount('#app');
