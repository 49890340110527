/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-18 22:29:27
 *@email: casudin.dev@gmail.com
 */

import { VueReCaptcha } from 'vue-recaptcha-v3';

export const install = ({ app }) => {
  app.use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_GOOGLE_SITE_KEY,
    loaderOptions: {
      autoHideBadge: true
    }
  });
};
