import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'basehome',
    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Home',
      requiresAuth: true
    },

    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/DashboardV.vue'),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHistory('#'),
  routes
});

export default router;
