import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { post, get } from '@/utils/request';
import Cookies from 'js-cookie';
import router from '@/router';
const COOKIE_IS_AUTH = 'loggedIn';
const COOKIE_TOKEN_KEY = 'tokenOndong';
const COOKIE_USER = 'me';
const COOKIE_ABILITIES = 'abilities';

const apiUrl = import.meta.env.VITE_API_URL;

export const authService = defineStore('authService', () => {
  const session = {
    isAuth: ref(Cookies.get(COOKIE_IS_AUTH) || false),
    token: ref(Cookies.get(COOKIE_TOKEN_KEY) || ''),
    user: ref(Cookies.get(COOKIE_USER) ? JSON.parse(Cookies.get(COOKIE_USER)) : null),
    abilities: ref(Cookies.get(COOKIE_ABILITIES) ? JSON.parse(Cookies.get(COOKIE_ABILITIES)) : [])
  };

  // getters
  const getIsAuth = computed(() => session.isAuth.value);
  const getToken = computed(() => session.token.value);
  const getUser = computed(() => session.user.value);
  const getAbilities = computed(() => session.abilities.value);
  const getOtpCode = computed(() => session.otp_code.value);

  //state

  // setters
  const login = async (data) => {
    try {
      const response = await post(`${apiUrl}v1/auth/login`, data);
      if (response) {
        Cookies.set(COOKIE_IS_AUTH, true, { expires: 7 });
        Cookies.set(COOKIE_TOKEN_KEY, response.data.token, { expires: 7 });
        session.isAuth.value = true;
        session.token.value = response.data.token;

        window.scrollTo(0, 0);
        setTimeout(async () => {
          await me();
        }, 1500);
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  const generateOtp = async (data) => {
    try {
      const response = await post(`${apiUrl}v1/generate-otp`, data);
      if (response) {
        //delete OLD OTP
        window.scrollTo(0, 0);
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  const verifyOtp = async (data) => {
    try {
      const response = await post(`${apiUrl}v1/verify-otp`, data);
      if (response) {
        //delete OLD OTP

        Cookies.set(COOKIE_IS_AUTH, true, { expires: 7 });
        Cookies.set(COOKIE_TOKEN_KEY, response.data.token, { expires: 7 });
        session.isAuth.value = true;
        session.token.value = response.data.token;

        window.scrollTo(0, 0);
        setTimeout(async () => {
          await me();
        }, 1500);
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  const logout = async () => {
    try {
      const response = await post(`${apiUrl}v1/auth/logout`, {});
      if (response.status) {
        session.isAuth.value = false;
        session.token.value = null;
        session.user.value = null;
        session.abilities.value = [];

        // Hapus cookies dengan memberikan waktu expire yang sama dengan saat penambahan
        Cookies.remove(COOKIE_IS_AUTH);
        Cookies.remove(COOKIE_TOKEN_KEY);
        Cookies.remove(COOKIE_USER);
        Cookies.remove(COOKIE_ABILITIES);
        Cookies.remove('TOKEN-COOKIE');
        await me();
        router.push({ name: 'auth' });
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  // me
  const me = async () => {
    try {
      const response = await get(`${apiUrl}v1/auth/me`);
      if (response) {
        session.isAuth.value = true;
        session.user.value = response.data;
        Cookies.set(COOKIE_USER, JSON.stringify(response.data), { expires: 1 });
        await getPermissions();
      }
      return response;
    } catch (error) {
      //console.error('Error during me:', error);
      return error;
    }
  };

  // abilities
  const getPermissions = async () => {
    try {
      const response = await get(`${apiUrl}v1/abilities`);
      if (response.length > 0) {
        session.abilities.value = response;
        Cookies.set(COOKIE_ABILITIES, JSON.stringify(response), { expires: 1 });
      } else {
        session.abilities.value = [];
        Cookies.set(COOKIE_ABILITIES, JSON.stringify([]), { expires: 1 });
      }
    } catch (error) {
      console.error('Error during getPermissions:', error);
    }
  };

  const setToken = (token) => {
    session.token.value = token;
    Cookies.set(COOKIE_IS_AUTH, true, { expires: 7 });
    Cookies.set(COOKIE_TOKEN_KEY, token, { expires: 7 });
    session.isAuth.value = true;
    setTimeout(async () => {
      await me();
    }, 1500);
  };

  return {
    getIsAuth,
    getToken,
    getUser,
    getAbilities,
    login,
    logout,
    me,
    getPermissions,
    setToken,
    generateOtp,
    getOtpCode,
    verifyOtp
  };
});
