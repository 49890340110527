import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Home',
      requiresAuth: true
    },

    children: [
      {
        path: '/nas',
        name: 'nas.index',
        component: () => import('@/views/Radius/Nas/IndexV.vue'),
        meta: {
          title: 'Nas',
          requiresAuth: true,
          Permissions: 'read_nas'
        }
      },
      {
        path: '/nas/create',
        name: 'nas.create',
        component: () => import('@/views/Radius/Nas/AddV.vue'),
        meta: {
          title: 'Add Nas',
          requiresAuth: true,
          Permissions: 'create_nas'
        }
      },
      {
        path: '/nas/:nasId/edit',
        name: 'nas.edit',
        component: () => import('@/views/Radius/Nas/EditV.vue'),
        meta: {
          title: 'Edit Nas',
          requiresAuth: true,
          Permissions: 'update_nas'
        }
      },
      //bandwidth
      {
        path: '/bandwidth',
        name: 'bandwidth.index',
        component: () => import('@/views/Radius/Profiles/Bandwidth/IndexV.vue'),
        meta: {
          title: 'Bandwidth',
          requiresAuth: true,
          Permissions: 'read_bandwidth_paket'
        }
      },
      {
        path: '/bandwidth/create',
        name: 'bandwidth.create',
        component: () => import('@/views/Radius/Profiles/Bandwidth/AddV.vue'),
        meta: {
          title: 'Add Bandwidth',
          requiresAuth: true,
          Permissions: 'create_bandwidth_paket'
        }
      },
      {
        path: '/bandwidth/:bandwidthId/edit',
        name: 'bandwidth.edit',
        component: () => import('@/views/Radius/Profiles/Bandwidth/EditV.vue'),
        meta: {
          title: 'Edit Bandwidth',
          requiresAuth: true,
          Permissions: 'update_bandwidth_paket'
        }
      },
      {
        //group-profile
        path: '/group-profile',
        name: 'group-profile.index',
        component: () => import('@/views/Radius/Profiles/Groups/IndexV.vue'),
        meta: {
          title: 'Group Profile',
          requiresAuth: true,
          Permissions: 'read_profile_group'
        }
      },
      {
        path: '/group-profile/create',
        name: 'group-profile.create',
        component: () => import('@/views/Radius/Profiles/Groups/AddV.vue'),
        meta: {
          title: 'Add Group Profile',
          requiresAuth: true,
          Permissions: 'create_profile_group'
        }
      },
      {
        path: '/group-profile/:groupProfileId/edit',
        name: 'group-profile.edit',
        component: () => import('@/views/Radius/Profiles/Groups/EditV.vue'),
        meta: {
          title: 'Edit Group Profile',
          requiresAuth: true,
          Permissions: 'update_profile_group'
        }
      },
      {
        //hotspot-profile
        path: '/profile-hotspot',
        name: 'profile-hotspot.index',
        component: () => import('@/views/Radius/Profiles/Hotspot/IndexV.vue'),
        meta: {
          title: 'Hotspot Profile',
          requiresAuth: true,
          Permissions: 'read_hotspot'
        }
      },
      {
        path: '/profile-hotspot/create',
        name: 'profile-hotspot.create',
        component: () => import('@/views/Radius/Profiles/Hotspot/AddV.vue'),
        meta: {
          title: 'Add Hotspot Profile',
          requiresAuth: true,
          Permissions: 'create_hotspot'
        }
      },
      {
        path: '/profile-hotspot/:profileHotspotId/edit',
        name: 'profile-hotspot.edit',
        component: () => import('@/views/Radius/Profiles/Hotspot/EditV.vue'),
        meta: {
          title: 'Edit Hotspot Profile',
          requiresAuth: true,
          Permissions: 'update_hotspot'
        }
      },
      {
        //profile-ppp
        path: '/profile-ppp',
        name: 'profile-ppp.index',
        component: () => import('@/views/Radius/Profiles/PPP/IndexV.vue'),
        meta: {
          title: 'PPP Profile',
          requiresAuth: true,
          Permissions: 'read_pppoe'
        }
      },
      {
        path: '/profile-ppp/create',
        name: 'profile-ppp.create',
        component: () => import('@/views/Radius/Profiles/PPP/AddV.vue'),
        meta: {
          title: 'Add PPP Profile',
          requiresAuth: true,
          Permissions: 'create_pppoe'
        }
      },
      {
        path: '/profile-ppp/:profilePppId/edit',
        name: 'profile-ppp.edit',
        component: () => import('@/views/Radius/Profiles/PPP/EditV.vue'),
        meta: {
          title: 'Edit PPP Profile',
          requiresAuth: true,
          Permissions: 'update_pppoe'
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
