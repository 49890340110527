/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-08-04 22:23:00
 *@email: casudin.dev@gmail.com
 */

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/sesions',
    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'session Settings',
      requiresAuth: true
    },
    children: [
      {
        path: 'users',
        name: 'session.user.index',
        component: () => import('@/views/Session/UsersV.vue'),
        meta: {
          title: 'Session Users',
          requiresAuth: true,
          Permissions: 'read_session_user'
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
