/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-18 22:49:43
 *@email: casudin.dev@gmail.com
 */
import Vue3Toastify from 'vue3-toastify';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const install = ({ app }) => {
  app.use(Vue3Toastify, {
    position: 'top-center',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.7,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    icon: true,
    rtl: false,
    maxToasts: 20,
    theme: 'auto',
    transition: 'flip',
    newestOnTop: true,
    dangerouslyHTMLString: true,
    toastClassName: 'custom-toast',
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter((t) => t.type === toast.type).length !== 0) {
        return false;
      }
      return toast;
    }
  });
  app.provide('toast', toast);
};
