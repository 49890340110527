/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-07-09 22:49:48
 *@email: casudin.dev@gmail.com
 */
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/invoices',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'INV customer',
      requiresAuth: true
    },
    children: [
      {
        path: 'customers',
        name: 'inv.customers.index',
        component: () => import('@/views/Invoices/IndexV.vue'),
        meta: {
          title: 'INV customers',
          requiresAuth: true,
          Permissions: 'read_tagihan'
        }
      },
      {
        path: ':custId/renew/:inIvId?', //invoice
        name: 'invoice.renew',
        component: () => import('@/views/Invoices/RenewV.vue'),
        meta: {
          title: 'Renew Customer',
          requiresAuth: true,
          Permissions: 'read_tagihan'
        }
      },
      {
        path: ':custId/print/:inIvId?', //invoice
        name: 'invoice.print',
        component: () => import('@/views/Invoices/InvoiceV.vue'),
        meta: {
          title: 'Renew Customer',
          requiresAuth: true,
          Permissions: 'read_tagihan'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
