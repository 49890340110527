/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-19 17:15:20
 *@email: casudin.dev@gmail.com
 */
import { createBootstrap } from 'bootstrap-vue-next';
// import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

export const install = ({ app }) => {
  app.use(createBootstrap({ components: true, directives: true })); // Change this line
};
