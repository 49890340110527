// import '@/utils/firebase';
import dayjs from 'dayjs';
// import eventBus from '@/event'
import 'dayjs/locale/id';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.locale('id');
dayjs.extend(relativeTime);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

// const localeTimeZone = dayjs.tz.guess()
// eslint-disable-next-line no-unused-vars
// import * as bootstrap from 'bootstrap';

// import './js/plugins/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import './js/plugins/bootstrap.min.js';
//swiper

import 'swiper';
//temlate
// import './js/fonts/custom-font';

// import './js/plugins/feather.min.js';
//
