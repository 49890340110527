// Import yang diperlukan
import { createI18n } from 'vue-i18n';
import { messages } from '@/utils/locales/messages';
import Cookies from 'js-cookie';
import { useI18n } from 'vue-i18n';

// Inisialisasi i18n di luar fungsi install
const i18n = createI18n({
  locale: Cookies.get('lang') ? Cookies.get('lang') : 'id',
  messages: messages,
  globalInjection: true,
  legacy: false,
  fallbackWarn: false,
  missingWarn: false
});

// Fungsi changeLang yang di-export

// Fungsi install
export const install = ({ app, router }) => {
  // Setup aplikasi Vue
  app.use(i18n);

  // Setup untuk mengembalikan $t dari useI18n()
  app.setup = () => {
    const { $t } = useI18n();
    return { $t };
  };

  // Konfigurasi global properties
  app.config.globalProperties.$t = (key, ...args) => i18n.global.t(key, args);
  app.config.globalProperties.$tc = (key, choice, ...args) => i18n.global.tc(key, choice, args);

  const changeLang = (lang) => {
    i18n.global.locale.value = lang;
    Cookies.set('lang', lang);
  };
  app.config.globalProperties.$changeLang = changeLang;

  // Mengubah judul dokumen berdasarkan meta title dari rute
  router.beforeEach((to, from, next) => {
    const DEF = import.meta.env.VITE_APP_NAME;
    if (to.meta.title) {
      document.title = i18n.global.t(to.meta.title) + ' | ' + DEF;
    }
    next();
  });
};
