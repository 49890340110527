/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-07-02 23:23:20
 *@email: casudin.dev@gmail.com
 */

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export const install = ({ app }) => {
  app.component('v-select', vSelect);
};
