import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/payment',

    component: () => import('@/layouts/PublicLayout.vue'),
    meta: {
      title: 'Public Payment',
      requiresAuth: false
    },
    children: [
      {
        path: 'dashboard',
        name: 'public.dashboard',
        component: () => import('@/views/Public/Payment.vue'),
        meta: {
          title: 'Dashboard Payment',
          requiresAuth: false
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
