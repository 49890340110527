import { authService } from '@/stores/authStore';
import NProgress from 'nprogress';

const authGuard = async (to, from, next) => {
  const authStore = authService();

  try {
    await authStore.getUser;
  } catch (error) {
    console.error('Error fetching user:', error);
    if (to.name !== 'auth') {
      next({ name: 'auth' });
      NProgress.done();
      return;
    }
    next(); // Penting untuk memanggil next() jika ini adalah halaman login
    return;
  }

  const permissions = authStore.getAbilities;
  const publicRoutes = ['public.dashboard']; // Sesuaikan dengan rute publik
  const DEFAULT_TITLE = import.meta.env.VITE_APP_NAME;

  document.title = (to.meta.title || '') + ' | ' + DEFAULT_TITLE;

  NProgress.start();

  // Jika rute publik, lanjutkan tanpa pengecekan autentikasi
  if (publicRoutes.includes(to.name)) {
    next(); // Pastikan next() dipanggil di sini
    NProgress.done();
    return;
  }

  // Jika membutuhkan autentikasi dan pengguna belum login, redirect ke halaman login
  if (to.matched.some((record) => record.meta.requiresAuth) && !authStore.getIsAuth) {
    if (to.name !== 'auth') {
      next({ name: 'auth' });
      NProgress.done();
      return;
    }
  }

  // Jika memerlukan izin dan pengguna tidak memiliki izin
  if (to.matched.some((record) => record.meta.Permissions)) {
    if (!permissions.includes(to.meta.Permissions)) {
      next({ name: 'error403' });
      NProgress.done();
      return;
    }
  }

  window.scrollTo(0, 0);

  // Ini penting, panggil next() setelah semua pengecekan selesai
  next();
  NProgress.done();
};

export default authGuard;
