import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'basehomeauth',
    component: () => import('@/layouts/AuthLayout.vue'),
    meta: {
      title: 'Login Page',
      requiresAuth: false
    },

    children: [
      {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/AuthV.vue'),
        meta: {
          title: 'Login Page',
          requiresAuth: false
        }
      },
      {
        //register
        path: '/register',
        name: 'register',
        component: () => import('@/views/RegisterV.vue'),
        meta: {
          title: 'Register Page',
          requiresAuth: false
        }
      },
      {
        //forgot password
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotV.vue'),
        meta: {
          title: 'Forgot Password Page',
          requiresAuth: false
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHistory('#'),
  routes
});

export default router;
