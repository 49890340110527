/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-18 22:52:27
 *@email: casudin.dev@gmail.com
 */

import { createHead } from '@unhead/vue'
export const install = ({ app }) => {
  const head = createHead()
  app.use(head)
}
