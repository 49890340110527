/** @format */

import axios from '@/utils/axios';
import NProgress from 'nprogress';
// import 'nprogress/nprogress.css'
import { authService } from '@/stores';
import Cookies from 'js-cookie';

// Fungsi untuk melakukan permintaan dengan tipe generik
const makeRequest = (method, url, data) => {
  return new Promise((resolve, reject) => {
    const auth = authService();
    const config = {
      method,
      url,
      data,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        NProgress.set(progress / 100);
      },
      onHandleUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        NProgress.set(progress / 100);
      },
      //Accept-Language
      headers: {
        Authorization: `Bearer ${auth.getToken}`,
        'Accept-Language': Cookies.get('lang') || 'id',
        'mb-radius-api-key': import.meta.env.VITE_X_API_KEY
      }
    };
    NProgress.start({});
    axios(config)
      .then((response) => {
        NProgress.done();
        resolve(response?.data);
      })
      .catch((error) => {
        const errors = error.response?.data?.message;
        let number = 1;
        if (typeof errors === 'object') {
          let messages = '';
          for (const key in errors) {
            messages += `${number}. ${errors[key]}<br>`;
            number++;
          }

          reject(messages);
        } else {
          NProgress.done();
        }
        reject(error);
      })
      .finally(() => {
        NProgress.done();
      });
  });
};

// Fungsi untuk melakukan permintaan POST dengan tipe generik
const post = (url, data) => {
  return makeRequest('post', url, data);
};

// Fungsi untuk melakukan permintaan GET dengan tipe generik
// eslint-disable-next-line no-unused-vars
const get = (url, data = null) => {
  return makeRequest('get', url);
};

// Fungsi untuk melakukan permintaan PUT dengan tipe generik
const put = (url, data) => {
  return makeRequest('put', url, data);
};

// Fungsi untuk melakukan permintaan DELETE dengan tipe generik
const del = (url) => {
  return makeRequest('delete', url);
};

export { post, get, put, del };
