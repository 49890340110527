/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-07-22 21:53:26
 *@email: casudin.dev@gmail.com
 */

import { InstallCodeMirror } from 'codemirror-editor-vue3';

import 'codemirror/theme/dracula.css';
//// language html
import 'codemirror/mode/htmlmixed/htmlmixed';
export const install = ({ app }) => {
  app.use(InstallCodeMirror, { componentName: 'CodeMirror' });
};
