/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-07-06 17:31:00
 *@email: casudin.dev@gmail.com
 */

import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: '/vouchers',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Home Vouchers',
      requiresAuth: true
    },
    children: [
      {
        //hotspot
        path: 'hotspot',
        name: 'voucher.hotspot.index',
        component: () => import('@/views/Vouchers/Hotspot/IndexV.vue'),
        meta: {
          title: 'Vouchers Hotspot',
          requiresAuth: true,
          Permissions: 'read_voucher_hotspot'
        }
      },

      //PPP
      {
        path: 'ppp',
        name: 'voucher.ppp.index',
        component: () => import('@/views/Vouchers/PPP/IndexV.vue'),
        meta: {
          title: 'Vouchers PPPoe',
          requiresAuth: true,
          Permissions: 'read_voucher_pppoe'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
