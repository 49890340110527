/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-18 23:11:05
 *@email: casudin.dev@gmail.com
 */
import 'vue3-perfect-scrollbar/style.css';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';

export const install = ({ app }) => {
  app.use(PerfectScrollbarPlugin);
};
