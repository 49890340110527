/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-09-06 20:22:18
 * @Email: casudin.dev@gmail.com
 */

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/whatsapp-logs',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'WhatsApp Logs',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'wa.index',
        component: () => import('@/views/Whatsapp/IndexV.vue'),
        meta: {
          title: 'Log WhatsApp',
          requiresAuth: true,
          Permissions: 'read_whatapp_log'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
