/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-07-06 17:31:00
 *@email: casudin.dev@gmail.com
 */

import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: '/customers',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Home customer',
      requiresAuth: true
    },
    children: [
      {
        //hotspot
        path: 'hotspot',
        name: 'customer.hotspot.index',
        component: () => import('@/views/Customers/Hotspot/IndexV.vue'),
        meta: {
          title: 'Customers Hotspot',
          requiresAuth: true,
          Permissions: 'read_pelanggan_hotspot'
        }
      },
      {
        path: 'hotspot/create',
        name: 'customer.hotspot.create',
        component: () => import('@/views/Customers/Hotspot/AddV.vue'),
        meta: {
          title: 'Create Customer Hotspot',
          requiresAuth: true,
          Permissions: 'create_pelanggan_hotspot'
        }
      },
      {
        path: 'hotspot/:hotId',
        name: 'customer.hotspot.edit',
        component: () => import('@/views/Customers/Hotspot/EditV.vue'),
        meta: {
          title: 'Edit Hotspot',
          requiresAuth: true,
          Permissions: 'update_pelanggan_hotspot'
        }
      },
      {
        path: 'hotspot/:hotId/profile',
        name: 'customer.hotspot.show',
        component: () => import('@/views/Customers/Hotspot/ProfileV.vue'),
        meta: {
          title: 'Show Hotspot',
          requiresAuth: true,
          Permissions: 'update_pelanggan_hotspot'
        }
      },
      {
        path: 'hotspot/:hotId/renew',
        name: 'customer.hotspot.renew',
        component: () => import('@/views/Customers/Hotspot/RenewV.vue'),
        meta: {
          title: 'Renew Customer Hotspot',
          requiresAuth: true,
          Permissions: 'update_pelanggan_hotspot'
        }
      },
      {
        path: 'hotspot/:hotId/print',
        name: 'customer.hotspot.print',
        component: () => import('@/views/Customers/Hotspot/InvoiceV.vue'),
        meta: {
          title: 'Print Customer Hotspot',
          requiresAuth: true,
          Permissions: 'update_pelanggan_hotspot'
        }
      },
      //PPP
      {
        path: 'ppp',
        name: 'customer.ppp.index',
        component: () => import('@/views/Customers/PPP/IndexV.vue'),
        meta: {
          title: 'Customers PPPoe',
          requiresAuth: true,
          Permissions: 'read_pelanggan_pppoe'
        }
      },
      {
        path: 'ppp/create',
        name: 'customer.ppp.create',
        component: () => import('@/views/Customers/PPP/AddV.vue'),
        meta: {
          title: 'Create Customer PPPoe',
          requiresAuth: true,
          Permissions: 'create_pelanggan_pppoe'
        }
      },
      {
        path: 'ppp/:pppId',
        name: 'customer.ppp.edit',
        component: () => import('@/views/Customers/PPP/EditV.vue'),
        meta: {
          title: 'Edit PPPoe',
          requiresAuth: true,
          Permissions: 'update_pelanggan_pppoe'
        }
      },
      {
        path: 'ppp/:pppId/profile',
        name: 'customer.ppp.show',
        component: () => import('@/views/Customers/PPP/ProfileV.vue'),
        meta: {
          title: 'Show Hotspot',
          requiresAuth: true,
          Permissions: 'update_pelanggan_pppoe'
        }
      },
      {
        path: 'ppp/:pppId/renew',
        name: 'customer.ppp.renew',
        component: () => import('@/views/Customers/PPP/RenewV.vue'),
        meta: {
          title: 'Renew Customer Pppoe',
          requiresAuth: true,
          Permissions: 'update_pelanggan_pppoe'
        }
      },
      {
        path: 'ppp/:pppId/print',
        name: 'customer.ppp.print',
        component: () => import('@/views/Customers/PPP/InvoiceV.vue'),
        meta: {
          title: 'Print Customer Pppoe',
          requiresAuth: true,
          Permissions: 'update_pelanggan_pppoe'
        }
      },
      {
        path: 'customers/maps',
        name: 'customer.maps',
        component: () => import('@/views/Customers/MapsV.vue'),
        meta: {
          title: 'Customers Maps',
          requiresAuth: true,
          Permissions: 'read_maps'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
