import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/affliates',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Affliate',
      requiresAuth: true
    },
    children: [
      {
        path: 'all',
        name: 'affiliate.all',
        component: () => import('@/views/Affiliate/Index.vue'),
        meta: {
          title: 'My Affiliate',
          requiresAuth: true,
          Permissions: 'read_affiliate'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
