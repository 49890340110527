/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-08-04 22:23:00
 *@email: casudin.dev@gmail.com
 */

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/error',
    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'session Settings',
      requiresAuth: true
    },
    children: [
      {
        path: '403',
        name: 'error403',
        component: () => import('@/views/errors/403V.vue'),
        meta: {
          title: 'Error 403',
          requiresAuth: true
        }
      },
      {
        //errors
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/errors/404V.vue'),
        meta: {
          requiresAuth: false,
          title: '404 Not Found'
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
