<template>
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <KeepAlive>
        <Suspense timeout="3">
          <component :is="Component"></component>

          <template #fallback>
            <!-- Pastikan elemen fallback memiliki satu elemen root -->
            <div>
              <div id="preloader" :class="{ active: loading }" v-if="loading">
                <div id="lds-ripple">
                  <div class="spinner-container">
                    <div
                      class="spinner-border text-primary avatar-sm"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <output class="visually-hidden">Loading...</output>
                    </div>
                    <div>Loading {{ loadingPercentage }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Suspense>
      </KeepAlive>
    </template>
    <!-- <PwaV /> -->
  </RouterView>
</template>

<script setup>
import { onMounted, ref, watchEffect, watch } from 'vue';
import { authService } from '@/stores/authStore';
import event from '@/event';
import { useRouter } from 'vue-router';
// import PwaV from './components/PwaV.vue';

const auth = authService();
const loading = ref(true);
const loadingPercentage = ref(0);
const router = useRouter();
const publicRoutes = ['public.dashboard', 'public.payment.success']; // Tambahkan route publik yang diperlukan, seperti payment
const curentRoute = ref(router.currentRoute.value.name);

onMounted(() => {
  // Periksa jika rute saat ini memerlukan autentikasi atau tidak
  if (!publicRoutes.includes(curentRoute.value)) {
    auth.me().finally(() => {
      loading.value = false;
    });
  } else {
    loading.value = false; // Jangan memeriksa autentikasi untuk rute publik
  }

  // Simulasi untuk loading
  setTimeout(() => {
    loading.value = true;
  }, 1000);
});

event.on('update_user', () => {
  auth.me();
});

// Pantau perubahan rute saat ini
watch(router.currentRoute, (newRoute) => {
  curentRoute.value = newRoute.name;

  // Jika pengguna tidak terautentikasi dan halaman bukan halaman publik, arahkan ke halaman login
  if (!auth.getIsAuth && !publicRoutes.includes(curentRoute.value)) {
    router.push({ name: 'auth' });
  }
});

// Cek jika status autentikasi berubah
watchEffect(() => {
  if (!auth.getIsAuth && !publicRoutes.includes(curentRoute.value)) {
    auth.me();
  }
});
</script>
