/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-18 22:20:43
 *@email: casudin.dev@gmail.com
 */
// import VueLazyload from 'vue-lazyload';
import VueLazyLoad from 'vue3-lazyload';

export const install = ({ app }) => {
  app.use(VueLazyLoad, {
    error: '/own/image.webp',
    loading: '/own/loader.svg',
    lifecycle: {
      loading: (el) => {
        console.log('loading', el);
      },
      error: (el) => {
        console.log('error', el);
      },
      loaded: (el) => {
        console.log('loaded', el);
      }
    }
  });
};
