/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-19 17:22:00
 *@email: casudin.dev@gmail.com
 */
import { createPopper } from '@popperjs/core';
export const install = ({ app }) => {
  app.config.globalProperties.$popper = createPopper;
};
