/**
 * axios setup to use mock service
 *
 * @format
 */

import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'vue3-toastify';
import router from '@/router';
import dayjs from 'dayjs';
import eventBus from '@/event';
import 'dayjs/locale/id';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
dayjs.locale('id');

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
// const apiUrl = `${import.meta.env.VITE_API_BASE}`;
const mode = import.meta.env.VITE_MODE;
//axios.defaults.baseURL = apiUrl
// axios.create({
//   withCredentials: true,
//   withXSRFToken: true
// });

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['api_key'] = import.meta.env.VITE_X_API_KEY;
axios.defaults.headers.common['X-Api-Key'] = import.meta.env.VITE_X_API_KEY;
axios.defaults.headers.common['App-Token'] = import.meta.env.VITE_X_API_KEY;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = window.location.origin;

//cors handle
//application/json
axios.defaults.headers.common['Accept'] = 'application/json';
//
// axios.defaults.headers.common['Accept'] = 'application/vnd.api+json';

eventBus.on('changeLang', (lang) => {
  axios.defaults.headers.common['Accept-Language'] = lang;
});

// //X-XSRF-TOKEN
// axios.defaults.headers.common['X-XSRF-TOKEN'] = Cookies.get('XSRF-TOKEN')

//timezone in header
const timezone = dayjs.tz.guess();
axios.defaults.headers.common['timezone'] = `${timezone}`;
// axios.defaults.headers.common['Accept-Language'] = Cookies.get('lang') || 'id';
//bearer token

axios.interceptors.request.use((config) => {
  if (config.method == 'post' || config.method == 'put' || config.method == 'delete') {
    // eslint-disable-next-line
    //return setCSRFToken().then((response) => config);
  }
  //proxy

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const handle401 = () => {
      Cookies.remove('loggedIn');
      Cookies.remove('tokenOndong');
      Cookies.remove('me');
      Cookies.remove('abilities');
      const cookieKey = {
        token_key: '',
        user: '',
        abilities: '',
        isLogin: false
      };
      Object.keys(cookieKey).forEach((key) => {
        Cookies.remove(key);
      });

      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }

      if (router.currentRoute.value.meta.requiresAuth) {
        console.log('ini error uyxxx');
        toast.error(error.response?.data?.message);
        setTimeout(() => {
          router.push({ name: 'auth' });
          window.location.reload();
        }, 1500);
      }
    };

    const handle400 = (erros) => {
      const arr = [];
      if (typeof erros === 'object') {
        Object.keys(erros).forEach((key) => {
          arr.push(erros[key]);
        });
        toast.error(arr.join('<br/>'));
      } else {
        toast.error(error?.response?.data?.message);
      }
    };

    const handle419 = () => {
      if (router.currentRoute.value.meta.requiresAuth) {
        toast.error(error.response?.data?.message);
        setTimeout(() => {
          router.push({ name: 'auth' });
        }, 1500);
      }
    };

    const handle422 = (erros) => {
      const arr = [];
      if (typeof erros === 'object') {
        Object.keys(erros).forEach((key) => {
          arr.push(erros[key]);
        });
        toast.error(arr.join('<br/>'));
      } else {
        toast.error(error?.response?.data?.message);
      }
    };

    switch (error.response?.status) {
      case 401:
        handle401();
        break;
      case 403:
        // location.assign('/site/forbidden')
        break;
      case 400:
        handle400(error.response?.data?.message);
        break;
      case 500:
        toast.error(error.response?.data?.message);
        if (mode === 'production') location.assign('/error');
        break;
      case 419:
        handle419();
        break;
      case 404:
        //location.assign( '/error' );
        console.log('ini error uy');
        toast.error(error.response?.data?.message);
        break;
      case 422:
        handle422(error.response?.data?.message);
        break;
      case 429:
        toast.error(error.response?.data?.message);
        break;
      case 502:
        toast.error("Server's Sedang Offline, Mohon Coba Kembali");
        break;
      case 413:
        toast.error('File yang anda upload terlalu besar');
        break;
      default:
        break;
    }

    return Promise.reject(new Error(error));
  }
);
export default axios;
