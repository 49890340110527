import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/odp',
    redirect: '/odp/index',
    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Odp Settings',
      requiresAuth: true
    },
    children: [
      {
        path: 'index',
        name: 'odp.index',
        component: () => import('@/views/Odp/IndexV.vue'),
        meta: {
          title: 'ODP Management',
          requiresAuth: true,
          Permission: 'read_odp'
        }
      },
      {
        path: 'create',
        name: 'odp.create',
        component: () => import('@/views/Odp/Add.vue'),
        meta: {
          title: 'Add ODP',
          requiresAuth: true,
          Permission: 'create_odp'
        }
      },
      {
        path: 'edit/:odpId',
        name: 'odp.edit',
        component: () => import('@/views/Odp/Edit.vue'),
        meta: {
          title: 'Edit ODP',
          requiresAuth: true,
          Permission: 'update_odp'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
