/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-05-19 17:25:57
 *@email: casudin.dev@gmail.com
 */
import Shimmer from 'vue3-shimmer';

export const install = ({ app }) => {
  app.use(Shimmer);
};
